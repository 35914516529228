import React, { useCallback } from 'react'
import {
  Box,
  Text,
  TextField,
} from 'components'
import { SetFilters, ApplyFilters, Filters, FilterOnChange } from 'hooks/useFilters'

export interface TextFieldFilterProps<T extends object, D extends object, K extends keyof T = keyof T> {
  filterName: string,
  filterDefinitions: Filters<T, D, K>,
  filters: T,
  liveFilter?: boolean
  setFilters: SetFilters<T>
  applyFilters: ApplyFilters
}

export function TextFieldFilter<T extends object, D extends object, K extends keyof T = keyof T>(props: TextFieldFilterProps<T, D, K>) {
  const { filterName, filterDefinitions, filters, liveFilter, setFilters, applyFilters } = props
  const filterDefinition = filterDefinitions.find((definition) => definition.name === filterName)

  const onChange: FilterOnChange<object> = useCallback((value) => {
    return { [filterName]: value }
  }, [filterName])

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (liveFilter) {
      // eslint-disable-next-line prefer-const
      let timer: number
      window.clearTimeout(timer)
      timer = window.setTimeout(applyFilters, 200)
    } else {
      if (event.key === "Enter") {
        event.preventDefault()
        event.stopPropagation()
        applyFilters()
      }
    }
  }, [liveFilter, applyFilters])

  return <Box width="100%" pb={4}>
    <Text variant="subtitle1" fontWeight="medium" pb={2}>{ filterDefinition.label }</Text>

    <TextField
      fullWidth
      label={filterDefinition.label}
      value={filters[filterName] || ''}
      onChange={(event) => {
        setFilters((filterDefinition.onChange || onChange)(event.target.value, filters))
      }}
      onKeyDown={onKeyDown}
    />
  </Box>
}

export default TextFieldFilter
