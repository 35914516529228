import { useEffect } from 'react'
import { useToken, UseToken } from './useToken'

const TOKEN_LIFESPAN = 3600 // in seconds
const REFRESH_AFTER = 1800 // in seconds

export const useRefreshToken = (): UseToken => {
  const { token, refresh, valid, parsed, refreshToken, setToken, ...tokenRest } = useToken()

  useEffect(() => {
    if (!!refresh) {
      if (!!parsed) {
        if (parsed.exp <= Math.round(Date.now() / 1000)) {
          refreshToken()
        } else {
          const refreshAfter = (parsed.exp - (TOKEN_LIFESPAN - REFRESH_AFTER)) * 1000
          const delay = refreshAfter - Date.now()
          if (delay >= 0) {
            const timeout = setTimeout(refreshToken, delay)
            return () => clearTimeout(timeout)
          } else {
            refreshToken()
          }
        }
      } else {
        refreshToken()
      }
    }
  }, [parsed, refresh, refreshToken])

  return {
    token,
    refresh,
    valid,
    parsed,
    refreshToken,
    setToken,
    ...tokenRest
  }
}

export default useRefreshToken