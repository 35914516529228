import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import { SentryLink } from 'apollo-link-sentry'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'


export type ClientNames = 'kitchen' | 'campus' | 'kiosk'

declare module '@apollo/client' {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface DefaultContext extends Record<string, any> {
    clientName?: ClientNames
  }
}

const abortController = new AbortController()

const httpLink = createUploadLink({
  uri: '/graphql',
  fetchOptions: {
    mode: "cors",
    signal: abortController.signal
  }
})

const campusLink = createUploadLink({
  uri: '/graphql/campus',
  fetchOptions: {
    mode: "cors",
    signal: abortController.signal
  }
})

const kitchenLink = createUploadLink({
  uri: '/graphql/kitchen',
  fetchOptions: {
    mode: "cors",
    signal: abortController.signal
  }
})

const kioskLink = createUploadLink({
  uri: '/graphql/kiosk',
  fetchOptions: {
    mode: "cors",
    signal: abortController.signal
  }
})

const sentryLink = new SentryLink()

const createAuthMiddleware: (token?: string) => ApolloLink = (token) => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    const authHeaders = {
      authorization: token ? `Bearer ${token}` : null,
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...authHeaders,
      }
    }))

    return forward(operation)
  })

  return authMiddleware
}

const cache = new InMemoryCache()

persistCache({
  cache,
  storage: new LocalStorageWrapper(localStorage),
})

// EXAMPLE: Whenever we find something we need to implement as a resolver
//
// const resolvers = {
//   vendorFulfillmentReport: {
//     loadedAt: (): Scalars['ISO8601DateTime']['input'] => {
//       return DateTime.now().toISOString()
//     }
//   }
// }

// Campus-specific client that hits the campus API
const campusClient: (token?: string) => ApolloClient<NormalizedCacheObject> = (token) => {
  return new ApolloClient({
    cache,
    // resolvers,
    link: ApolloLink.from([createAuthMiddleware(token), sentryLink, (campusLink as unknown) as ApolloLink])
  })
}

// Kitchen-specific client that hits the kitchen API
const kitchenClient: (token?: string) => ApolloClient<NormalizedCacheObject> = (token) => {
  return new ApolloClient({
    cache,
    // resolvers,
    link: ApolloLink.from([createAuthMiddleware(token), sentryLink, (kitchenLink as unknown) as ApolloLink])
  })
}

// Kiosk-specific client that hits the kitchen API
const kioskClient: (token?: string) => ApolloClient<NormalizedCacheObject> = (token) => {
  return new ApolloClient({
    cache,
    // resolvers,
    link: ApolloLink.from([createAuthMiddleware(token), sentryLink, (kioskLink as unknown) as ApolloLink])
  })
}

// Generic/dynamic client that accepts a contextual clientName to either hit the campus, kitchen or generic API
const dynamicClient: (token?: string) => ApolloClient<NormalizedCacheObject> = (token) => {
  return new ApolloClient({
    cache,
    // resolvers,
    link: ApolloLink.split(
      operation => operation.getContext().clientName === 'campus',
      ApolloLink.from([createAuthMiddleware(token), sentryLink, (campusLink as unknown) as ApolloLink]),
      ApolloLink.split(
        operation => operation.getContext().clientName === 'kitchen',
        ApolloLink.from([createAuthMiddleware(token), sentryLink, (kitchenLink as unknown) as ApolloLink]),
        ApolloLink.split(
          operation => operation.getContext().clientName === 'kiosk',
          ApolloLink.from([createAuthMiddleware(token), sentryLink, (kioskLink as unknown) as ApolloLink]),
          ApolloLink.from([createAuthMiddleware(token), sentryLink, (httpLink as unknown) as ApolloLink]),
        ),
      ),
    )
  })
}

export const createClient: (token?: string, clientName?: ClientNames) => ApolloClient<NormalizedCacheObject> = (token, clientName) => {
  if (clientName === 'campus') {
    return campusClient(token)
  } else if (clientName === 'kitchen') {
    return kitchenClient(token)
  } else if (clientName === 'kiosk') {
    return kioskClient(token)
  } else {
    return dynamicClient(token)
  }
}

export default createClient