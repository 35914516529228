import { useCallback } from 'react'
import { useGlobalContext, GlobalContext } from './useGlobalContext'
import axios from 'axios'

export type ClaimRole = 'school' | 'user' | 'vendor' | 'admin'
export type ClaimRoles = ClaimRole[]

export type TokenClaims = {
  [key in 'x-hasura-allowed-roles']: ClaimRoles
} & {
  [key in 'x-hasura-default-role']: ClaimRole
}

export type ParsedToken = {
  [key in 'https://hasura.io/jwt/claims']: TokenClaims
} & {
  email: string
  user_id: string
  aud: string
  exp: number
  ist: number
  iss: string
  sub: string
}

const parseToken = (token: string): ParsedToken => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const valid = (token: string | undefined): boolean => {
  if (!token) return false

  try {
    const parsed = parseToken(token)
    return parsed.exp && parsed.exp > Math.round(Date.now() / 1000)
  } catch (error) {
    return false
  }
}

export const refreshAuthToken = (refresh: string, setToken: (token: string, refresh: string) => void): Promise<void> => {
  return axios.post(
    '/users/refresh',
    { refresh_token: refresh },
    { headers: { 'Accept': 'application/json' } }
  ).then((response) => {
    setToken(response.headers['authorization'], response.data.refresh)
  }).catch((error) => {
    if (error.response && error.response.status === 401) {
      setToken(undefined, undefined)
    } else {
      setToken(undefined, refresh)
    }
  })
}

export type UseToken = Pick<GlobalContext, "token" | "refresh" | "setToken"> & {
  valid: boolean
  parsed?: ParsedToken
  role?: ClaimRole
  roles?: ClaimRoles
  refreshToken: () => void
}

export const useToken = (): UseToken => {
  const { token, refresh, setToken } = useGlobalContext()
  const tokenValid = valid(token) // TODO useMemo, rename/swap valid vs tokenValid
  const parsed = tokenValid ? parseToken(token) : undefined // TODO useMemo

  // TODO useMemo for these
  const role = parsed !== undefined ? parsed['https://hasura.io/jwt/claims']['x-hasura-default-role'] : undefined
  const roles: ClaimRoles = parsed !== undefined ? parsed['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'] : []

  const refreshToken = useCallback(() => refreshAuthToken(refresh, setToken), [refresh, setToken])

  return {
    valid: tokenValid,
    token,
    refresh,
    parsed,
    role,
    roles,
    refreshToken,
    setToken
  }
}

export default useToken
