import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import { createTheme, Theme, ThemeOptions, PaletteColor, PaletteColorOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      drawerWidth: number
      formWidth: number
      formMargins: number | string
      pageWidth: number
      pageMargins: number | string
    }
  }

  interface ThemeOptions {
    sizes?: {
      drawerWidth?: number
      formWidth?: number
      formMargins?: number | string
      pageWidth?: number
      pageMargins?: number | string
    }
  }

  interface Palette {
    plain: PaletteColor
    branding: PaletteColor
    kitchen: PaletteColor
    campus: PaletteColor
    kiosk: PaletteColor
    luxo: PaletteColor
  }

  interface PaletteOptions {
    plain?: PaletteColorOptions
    branding?: PaletteColorOptions
    kitchen?: PaletteColorOptions
    campus?: PaletteColorOptions
    kiosk?: PaletteColorOptions
    luxo?: PaletteColorOptions
  }
}

const fontFamily = 'Rubik, Helvetica, Arial, sans-serif'

export const options: ThemeOptions = {
  typography: {
    fontFamily: fontFamily,
  },

  palette: {
    plain: {
      light: '#969696',
      main: '#323232',
      contrastText: '#FFFFFF',
    },
    branding: {
      light: '#F3F0FF',
      main: '#7B61FF',
      contrastText: '#FFFFFF',
    },
    campus: {
      main: "#FF7942",
      contrastText: '#FFFFFF',
    },
    kitchen: {
      main: "#1FB998",
      contrastText: '#FFFFFF',
    },
    kiosk: {
      main: "#7B61FF",
      contrastText: '#FFFFFF',
    },
    luxo: {
      main: "#349ceb",
      contrastText: '#FFFFFF',
    },
  },

  shape: {
    borderRadius: 10,
  },

  sizes: {
    drawerWidth: 36,
    formWidth: 108,
    // formMargins: 'auto',
    pageWidth: 108,
    // pageMargins: 'auto',
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: `
          html, body {
            font-family: ${fontFamily};
          }
        `
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      }
    },

    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          boxShadow: 'none',
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          borderBottomColor: theme.palette.divider,
          '@media print': {
            display: 'none',
          },
        })
      }
    },

    MuiAlert: {
    // EXAMPLE: Defining the variant styles to go along with the EXAMPLE variant="whatever" defined in /src/components/Alert/Alert.tsx
    //   variants: [
    //     {
    //       props: { variant: 'whatever' },
    //       style: ({ theme }) => ({
    //         SOME STYLES HERE...
    //       }),
    //     },
    //   ],
    },

    MuiButton: {
      styleOverrides: {
        root: () => ({
          textTransform: 'unset',
          fontSize: '1rem',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          }
        }),
        outlinedInherit: ({ theme }) => ({
          borderColor: theme.palette.divider,
          color: theme.palette.text.primary,
        }),
        containedInherit: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    // EXAMPLE: Defining the variant styles to go along with the EXAMPLE variant="whatever" defined in /src/components/Button/Button.tsx
    //   variants: [
    //     {
    //       props: { variant: 'whatever' },
    //       style: ({ theme }) => ({
    //         SOME STYLES HERE...
    //       }),
    //     },
    //   ],
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          boxShadow: 'none',
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .MuiDataGrid-editInputCell, & .MuiDataGrid-cell': {
            fontSize: '1rem',
          },
          '& .MuiInputBase-root': {
            borderRadius: 0,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }
        })
      }
    },

    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) => ({
          flexShrink: 0,
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(theme.sizes.drawerWidth)
          },
          '@media print': {
            display: 'none',
          },
        }),
        paper: ({ theme }) => ({
          boxSizing: 'border-box',
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(theme.sizes.drawerWidth)
          }
        })
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'bold'
        }
      }
    },
  }
}

export const theme: Theme = createTheme(options)

export default theme
