import { useEffect } from 'react'
import { setUser } from '@sentry/react'
import { meQuery } from 'graphql/queries'
import { useQuery } from 'hooks'
import type { Organization, Location, User, SchoolLocation, VendorLocation, School, Vendor } from 'graphql/schema/graphql'

// TODO: We should pull these from the backend
// NOTE: These are the same across locations and organizations
export const RoleLevels = {
  ADMIN: 100,
  STAFF: 50,
  KIOSK: 40,
}
export const AdminRoleLevels = {
  ADMIN: 100,
  SUPPORT: 80,
  SCHOOLS: 60,
  VENDORS: 60,
  SALES: 50,
}

export const useMe = () => {
  const { data, ...rest } = useQuery<{ me: User }>(meQuery)

  useEffect(() => {
    if (data?.me?.id) {
      setUser({ id: data.me.id, email: data.me.emailAddress })
    } else {
      setUser(null)
    }
  }, [ data?.me?.id, data?.me?.emailAddress ])

  const hasAdminRole = (role: string):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "AdminRole" && (userRole.role === role || userRole.level >= AdminRoleLevels[userRole.role])
    })

    return !!foundRole
  }

  const hasAdminLevel = (level: number):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "AdminRole" && userRole.level >= level
    })

    return !!foundRole
  }

  const hasOrganizationRole = (role: string, organization: Organization | School | Vendor | Location):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "OrganizationRole" && userRole.organizationId === organization.id &&
        (userRole.role === role || userRole.level >= RoleLevels[userRole.role])
    })

    return !!foundRole
  }

  const hasAnyOrganizationRole = (role: string, organizationType: 'SCHOOL' | 'VENDOR'):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "OrganizationRole" && userRole.organizationType === organizationType &&
        (userRole.role === role || userRole.level >= RoleLevels[userRole.role])
    })

    return !!foundRole
  }

  const hasOrganizationLevel = (level: number, organization: Organization | School | Vendor | Location):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "OrganizationRole" && userRole.organizationId === organization.id && userRole.level >= level
    })

    return !!foundRole
  }

  const hasAnyOrganizationLevel = (level: number, organizationType: 'SCHOOL' | 'VENDOR'):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return userRole.__typename == "OrganizationRole" && userRole.organizationType === organizationType && userRole.level >= level
    })

    return !!foundRole
  }

  const hasLocationRole = (role: string, location: Location | SchoolLocation | VendorLocation):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return ((userRole.__typename == "LocationRole" && userRole.locationId === location.id) || (userRole.__typename == "OrganizationRole" && userRole.organizationId === (location.organization?.id || location.organizationId))) &&
        (userRole.role === role || userRole.level >= RoleLevels[userRole.role])
    })

    return !!foundRole
  }

  const hasAnyLocationRole = (role: string, locationType: 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return (userRole.__typename == "LocationRole" && userRole.locationType === locationType) &&
        (userRole.role === role || userRole.level >= RoleLevels[userRole.role])
    })

    return !!foundRole
  }

  const hasLocationLevel = (level: number, location: Location | SchoolLocation | VendorLocation):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return ((userRole.__typename == "LocationRole" && userRole.locationId === location.id) || (userRole.__typename == "OrganizationRole" && userRole.organizationId === (location.organization?.id || location.organizationId))) &&
        userRole.level >= level
    })

    return !!foundRole
  }

  const hasAnyLocationLevel = (level: number, locationType: 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    const foundRole = data?.me?.userRoles?.find((userRole) => {
      return (userRole.__typename == "LocationRole" && userRole.locationType === locationType) &&
        userRole.level >= level
    })

    return !!foundRole
  }

  const hasRole = (role: string, context?: Organization | School | Vendor | Location | SchoolLocation | VendorLocation):boolean => {
    switch (context?.__typename) {
      case undefined:
        return hasAdminRole(role)
      case 'Organization':
        return hasOrganizationRole(role, context)
      case 'School':
        return hasOrganizationRole(role, context)
      case 'Vendor':
        return hasOrganizationRole(role, context)
      case 'Location':
        return hasLocationRole(role, context)
      case 'SchoolLocation':
        return hasLocationRole(role, context)
      case 'VendorLocation':
        return hasLocationRole(role, context)
    }
  }

  const hasAnyRole = (role: string, contextType?: 'SCHOOL' | 'VENDOR' | 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    switch (contextType) {
      case undefined:
        return hasAdminRole(role)
      case 'SCHOOL':
        return hasAnyOrganizationRole(role, contextType) || hasAnyLocationRole(role, 'SCHOOL_LOCATION')
      case 'VENDOR':
        return hasAnyOrganizationRole(role, contextType) || hasAnyLocationRole(role, 'VENDOR_LOCATION')
      case 'SCHOOL_LOCATION':
        return hasAnyLocationRole(role, contextType) || hasAnyOrganizationRole(role, 'SCHOOL')
      case 'VENDOR_LOCATION':
        return hasAnyLocationRole(role, contextType) || hasAnyOrganizationRole(role, 'VENDOR')
    }
  }

  const hasLevel = (level: number, context?: Organization | School | Vendor | Location | SchoolLocation | VendorLocation):boolean => {
    switch (context?.__typename) {
      case undefined:
        return hasAdminLevel(level)
      case 'Organization':
        return hasOrganizationLevel(level, context)
      case 'School':
        return hasOrganizationLevel(level, context)
      case 'Vendor':
        return hasOrganizationLevel(level, context)
      case 'Location':
        return hasLocationLevel(level, context)
      case 'SchoolLocation':
        return hasLocationLevel(level, context)
      case 'VendorLocation':
        return hasLocationLevel(level, context)
    }
  }

  const hasAnyLevel = (level: number, contextType?: 'SCHOOL' | 'VENDOR' | 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    switch (contextType) {
      case undefined:
        return hasAdminLevel(level)
      case 'SCHOOL':
        return hasAnyOrganizationLevel(level, contextType) || hasAnyLocationLevel(level, 'SCHOOL_LOCATION')
      case 'VENDOR':
        return hasAnyOrganizationLevel(level, contextType) || hasAnyLocationLevel(level, 'VENDOR_LOCATION')
      case 'SCHOOL_LOCATION':
        return hasAnyLocationLevel(level, contextType) || hasAnyOrganizationLevel(level, 'SCHOOL')
      case 'VENDOR_LOCATION':
        return hasAnyLocationLevel(level, contextType) || hasAnyOrganizationLevel(level, 'VENDOR')
    }
  }

  return {
    ...rest,
    data: {
      me: null,
      ...data
    },
    hasAdminRole,
    hasAdminLevel,
    hasOrganizationRole,
    hasOrganizationLevel,
    hasLocationRole,
    hasLocationLevel,
    hasRole,
    hasLevel,
    hasAnyOrganizationRole,
    hasAnyOrganizationLevel,
    hasAnyLocationRole,
    hasAnyLocationLevel,
    hasAnyRole,
    hasAnyLevel,
  }
}

export default useMe
