import store2 from 'store2'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { createReduxEnhancer } from '@sentry/react'
import drawerSettingsReducer from 'reducers/drawerSettings'
import helpDialogReducer from 'reducers/helpDialog'

export const LocalStorageKey = 'reduxStore'

export const store = configureStore({
  reducer: {
    drawerSettings: drawerSettingsReducer,
    helpDialog: helpDialogReducer,
  },
  preloadedState: store2.get(LocalStorageKey, {}),
  enhancers: [ createReduxEnhancer() ],
})

store.subscribe(() => store2.set(LocalStorageKey, store.getState()))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store