/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from "react"
import { useNavigate } from 'react-router-dom'
import { TaskWithComponent } from "components/Todos"
import useMe from "./useMe"
import useResolveTodo, { ResolveTodoMutationData } from "./useResolveTodo"
import useIgnoreTodo, { IgnoreTodoMutationData } from "./useIgnoreTodo"
import { ApolloQueryResult, FetchResult } from "@apollo/client"
import { TodosPayload } from 'graphql/schema/graphql'

export type ResolveConfig<TData> = { navigateToNext?: boolean, onSuccess?: (response: FetchResult<TData>) => void, onError?: (response: FetchResult<TData>) => void }
export type NavigateToConfig = { onlyIncomplete?: boolean, cycle?: boolean, refetchedTodos?: TaskWithComponent[] }

export type ResolveFn = (todo: TaskWithComponent, config?: ResolveConfig<ResolveTodoMutationData>) => Promise<FetchResult<ResolveTodoMutationData>>
export type ResolveActiveFn = (config?: ResolveConfig<ResolveTodoMutationData>) => Promise<FetchResult<ResolveTodoMutationData>>
export type IgnoreFn = (todo: TaskWithComponent, config?: ResolveConfig<IgnoreTodoMutationData>) => Promise<FetchResult<IgnoreTodoMutationData>>
export type IgnoreActiveFn = (config?: ResolveConfig<IgnoreTodoMutationData>) => Promise<FetchResult<IgnoreTodoMutationData>>
export type NavigateToFn = (config?: NavigateToConfig) => void
export type RefetchFn<TData=any, TVariables=any> = (variables?: Partial<TData>) => Promise<ApolloQueryResult<TVariables>>

export type TodosWizard<TData=any, TVariables=any> = {
  todos: TaskWithComponent[],
  // TODO: We should make the types here more explicit
  incomplete: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  completed: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  ignored: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  resolved: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  required: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  blocking: (todoFn?: (todo: TaskWithComponent) => any) => any[]
  blocked: (location: string) => boolean
  activeTodo?: TaskWithComponent
  activeStep?: number
  refetch: RefetchFn<TData, TVariables>
  resolve: ResolveFn
  resolveActiveTodo: ResolveActiveFn
  ignore: IgnoreFn
  ignoreActiveTodo: IgnoreActiveFn
  navigateToNext: NavigateToFn
  navigateToPrev: NavigateToFn
  navigateToTodo: (todo: TaskWithComponent) => void
  navigateToTodos: () => void
  navigateToBlocking: (location: string) => void
}

export type TodosWizardArgs<TData=any, TVariables=any> = {
  todos: TaskWithComponent[]
  todo?: TaskWithComponent
  refetch: RefetchFn<TData, TVariables>
}

export interface GroupedTodos {
  incomplete: number[]
  completed: number[]
  ignored: number[]
  resolved: number[]
  required: number[]
  blocking: number[]
}

export const useTodosWizard = <TData=any, TVariables=any>({ todos, todo, refetch }: TodosWizardArgs<TData, TVariables>): TodosWizard => {
  const { refetch: refetchMe } = useMe()
  const navigateTo = useNavigate()
  const [ resolveTodo ] = useResolveTodo()
  const [ ignoreTodo ] = useIgnoreTodo()
  const activeStep = useMemo(() => todos?.findIndex((t) => todo?.id === t.id) || 0, [ todos, todo ])
  const activeTodo = useMemo(() => todos && todos[activeStep], [ todos, activeStep ])

  const groupedTodos: GroupedTodos = useMemo(() => {
    const grouped: GroupedTodos = {
      incomplete: [],
      completed: [],
      ignored: [],
      resolved: [],
      required: [],
      blocking: [],
    }

    todos?.forEach((todo, idx) => {
      switch (todo.status) {
        case 'incomplete':
          grouped.incomplete.push(idx)
          break
        case 'completed':
          grouped.completed.push(idx)
          break
        case 'ignored':
          grouped.ignored.push(idx)
          break
        default:
          break
      }

      if (todo.resolved) grouped.resolved.push(idx)
      if (todo.definition.definition.required && todo.status === 'incomplete') grouped.required.push(idx)
      if (todo.definition.definition.blocking && todo.status === 'incomplete') grouped.blocking.push(idx)
    })

    return grouped
  }, [ todos ])

  const incomplete = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.incomplete.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const completed = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.completed.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const ignored = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.ignored.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const resolved = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.resolved.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const required = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.required.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const blocking = useCallback((todoFn?: (todo: TaskWithComponent) => any) => {
    return groupedTodos.blocking.map((idx) => {
      const todo = todos[idx]
      if (todoFn) return todoFn(todo)

      return todo
    })
  }, [ todos, groupedTodos ])

  const blocked = useCallback((location: string) => {
    return blocking().length > 0 && !location.match(/\/(todos|profile|logout|login).*/)
  }, [ blocking ])

  const navigateToNext: NavigateToFn = useCallback((config={}) => {
    const { onlyIncomplete=true, cycle=true, refetchedTodos } = config
    const matchTodos = refetchedTodos || todos

    let nextTodo = matchTodos.find((todo, idx) => idx > activeStep && (!onlyIncomplete || todo.status === 'incomplete'))

    if (!nextTodo && cycle) {
      nextTodo = matchTodos.find((todo, idx) => idx !== activeStep && (!onlyIncomplete || todo.status === 'incomplete'))
    }

    if (nextTodo) {
      navigateTo(`/todos/${nextTodo.id}`, { replace: true })
    } else {
      navigateTo('/todos', { replace: true })
    }
  }, [ todos, activeStep, navigateTo ])

  const navigateToPrev: NavigateToFn = useCallback((config={}) => {
    const { onlyIncomplete=true, cycle=true, refetchedTodos } = config
    const matchTodos = refetchedTodos || todos

    let prevTodo = matchTodos.filter((todo, idx) => idx < activeStep && (!onlyIncomplete || todo.status === 'incomplete')).reverse()[0]

    if (!prevTodo && cycle) {
      prevTodo = matchTodos.filter((todo, idx) => idx !== activeStep && (!onlyIncomplete || todo.status === 'incomplete')).reverse()[0]
    }

    if (prevTodo) {
      navigateTo(`/todos/${prevTodo.id}`, { replace: true })
    } else {
      navigateTo('/todos', { replace: true })
    }
  }, [ todos, activeStep, navigateTo ])

  const navigateToTodo = useCallback((todo: TaskWithComponent) => {
    navigateTo(`/todos/${todo.id}`, { replace: true })
  }, [ navigateTo ])

  const navigateToTodos = useCallback(() => {
    navigateTo('/todos', { replace: true })
  }, [ navigateTo ])

  const navigateToBlocking = useCallback((location: string) => {
    if (blocking().length > 0 && !location.match(/\/(todos|profile|logout).*/))
      navigateTo(`/todos/${blocking()[0].id}`, { replace: true })
  }, [ blocking, navigateTo ])

  const resolve: ResolveFn = useCallback(async (todo, config) => {
    const response = await resolveTodo({ variables: { todoId: todo.id } })

    if (response.errors && response.errors.length > 0) {
      if (config?.onError) config.onError(response)
    } else if (response.data?.resolveTodo?.errors && response.data.resolveTodo.errors.length > 0) {
      if (config?.onError) config.onError(response)
    } else {
      const refetched = await refetch() as unknown as ApolloQueryResult<{ todos: TodosPayload }>
      refetchMe() // refresh the total todos count in the location selector
      if (config?.onSuccess) config.onSuccess(response)
      if (config?.navigateToNext) navigateToNext({ refetchedTodos: refetched.data?.todos?.rows as TaskWithComponent[]})
    }

    return response
  }, [ resolveTodo, refetch, refetchMe, navigateToNext ])

  const resolveActiveTodo: ResolveActiveFn = useCallback((config) => {
    return resolve(activeTodo, config)
  }, [ resolve, activeTodo ])

  const ignore: IgnoreFn = useCallback(async (todo, config) => {
    const response = await ignoreTodo({ variables: { todoId: todo.id } })

    if (response.errors && response.errors.length > 0) {
      if (config?.onError) config.onError(response)
    } else if (response.data?.ignoreTodo?.errors && response.data.ignoreTodo.errors.length > 0) {
      if (config?.onError) config.onError(response)
    } else {
      const refetched = await refetch() as unknown as ApolloQueryResult<{ todos: TodosPayload }>
      refetchMe() // refresh the total todos count in the location selector
      if (config?.onSuccess) config.onSuccess(response)
      if (config?.navigateToNext) navigateToNext({ refetchedTodos: refetched.data?.todos?.rows as TaskWithComponent[]})
    }

    return response
  }, [ ignoreTodo, refetch, refetchMe, navigateToNext ])

  const ignoreActiveTodo: IgnoreActiveFn = useCallback((config) => {
    return ignore(activeTodo, config)
  }, [ ignore, activeTodo ])

  return {
    todos,
    ...groupedTodos,
    incomplete,
    completed,
    ignored,
    resolved,
    required,
    blocking,
    blocked,
    activeTodo,
    activeStep,
    refetch,
    resolve,
    resolveActiveTodo,
    ignore,
    ignoreActiveTodo,
    navigateToNext,
    navigateToPrev,
    navigateToTodo,
    navigateToTodos,
    navigateToBlocking,
  }
}

export default useTodosWizard
